import '../scss/main.scss';
import '../scss/careers.scss';
import './_common.js';
import geolocation from './util/user_location';
import analytics from './util/analytics.js';
import Vue from './_vue-signleton';
import Helpers from './util/helpers';

const isBot = /Googlebot|Google-Structured-Data-Testing-Tool|Bingbot|AdIdxBot|BingPreview|DuckDuckGo|DuckDuckBot/i.test(
  window.navigator.userAgent
);

Vue.setOptions({
  delimiters: ['${', '}'],
  el: '#greenhouse',
  data() {
    let allCountries = window.pageData.jobData.countries || [],
      params = Helpers.deparam(window.location.search);

    // Highly unlikely, but ensure our jobs all have their countries in the country selector.
    let jobCountries = window.pageData.jobData.jobs.map((j) =>
      j.country ? j.country.trim() : null
    );
    jobCountries = jobCountries.filter(Boolean);
    jobCountries = Array.from(new Set(jobCountries));
    jobCountries.forEach((country) => {
      let name = country.toLowerCase();
      if (!allCountries.find((c) => c.toLowerCase() === name)) {
        allCountries.push(country);
      }
    });
    allCountries.sort();

    let service = '';
    if (params && Object.hasOwnProperty.call(params, 'expertise')) {
      let services = Array.from(document.querySelectorAll('select .services')).map((o) => o.value),
        // If we have more than one grab the first one
        serviceParam = Array.isArray(params.expertise) ? params.expertise[0] : params.expertise;

      // make the search case insensitive
      service = services.find(
        (s) => s.localeCompare(serviceParam, undefined, { sensitivity: 'base' }) === 0
      );
    }

    return {
      countries: allCountries,
      jobs: window.pageData.jobData.jobs,
      department: '',
      country: '',
      city: '',
      brand: '',
      service: service ? service : '',
      isLocalPage: window.pageData.isLocalPage,
      parent: window.pageData.parent
    };
  },

  computed: {
    /**
     * Determine the values in the cities filter based off the selected country.
     */
    cities() {
      if (this.country) {
        let cities = this.jobs
          .filter((j) => j.country === this.country)
          .map((j) => {
            // US cities get a state too if we have one.
            if (this.country.toLowerCase() === 'united states' && j.state) {
              return j.city + ', ' + j.state;
            }
            return j.city;
          });
        cities = Array.from(new Set(cities));
        cities.sort();
        return cities;
      }
      return [];
    },

    /**
     * Unique list of brands generated for filtering.
     */
    brands() {
      let brands = this.jobs.map((j) => j.brand).filter(Boolean);
      brands = Array.from(new Set(brands));
      brands.sort();
      return brands;
    },

    hasFilters() {
      if (this.isLocalPage) {
        return (
          this.city ||
          this.department ||
          (this.parentType === 'office' ? this.service : this.country)
        );
      }

      return this.country || this.city || this.department || this.brand || this.service;
    },

    /**
     * Compute the IDs of the jobs to show based on selected filters.
     * @return {Array} The array of job IDs.
     */
    idsToShow() {
      return this.jobs
        .filter((j) => {
          var keep = true;
          if (this.department) {
            // Use == because this.department may be a string.
            keep = j.departments.some((d) => d.id == this.department);
          }

          if (this.country) {
            keep = keep && j.country === this.country;
          }

          if (this.city) {
            keep = keep && j.city === this.city;
          }

          if (this.brand) {
            keep = keep && j.brand === this.brand;
          }

          if (this.service) {
            keep = keep && j.services.includes(this.service);
          }

          return keep;
        })
        .map((j) => j.id);
    },

    /**
     * Get the parent type
     * @return {String} The type.
     */
    parentType() {
      return this.parent.section.handle;
    }
  },

  watch: {
    country() {
      this.city = '';
    }
  },

  mounted() {
    if (this.isLocalPage && this.parent) {
      this.$set(this, this.parentType === 'office' ? 'country' : 'service', this.parent.title);
    } else {
      // Set country based on user location.
      geolocation.location.then((userLocation) => {
        let countryName = userLocation.data.countryName;
        if (countryName === 'China') {
          countryName = 'Greater China';
        }

        if (this.countries.includes(countryName)) {
          this.country = countryName;
        }
      });
    }
    // Set jobs links based on QueryString
    if (document.location.search !== '') {
      document.querySelectorAll('.job-listing a').forEach(($a) => {
        if ($a.href.includes('?')) {
          $a.href = $a.href + document.location.search.replace('?', '&');
        } else {
          $a.href = $a.href + document.location.search;
        }
      });
      let button = document.querySelector('.all-jobs .button');
      if (button) {
        button.href = window.pageData.siteUrl + window.location.search + '#jobs';
      }
    }
  },

  filters: {
    /**
     * Capitalize the string provided.
     * @param {String} str The string.
     * @return {String} The string.
     */
    capitalize(str) {
      if (str && typeof str === 'string') {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
      return '';
    }
  },

  methods: {
    clearFilters() {
      if (!this.isLocalPage) {
        this.brand = '';
        this.country = '';
        this.service = '';
      } else {
        this.$set(this, this.parentType === 'office' ? 'service' : 'country', '');
      }

      this.city = '';
      this.department = '';
    }
  }
});
